import React, { useContext, useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import AspectRatio from "~components/utils/aspect_ratio/aspect_ratio";
import { isEditorRoute } from "~utils/storyblok/storyblok";
import useIntersect from "~hooks/use_intersect/use_intersect";
import useSettingsClassNames from "~hooks/use_settings_classnames/use_settings_classnames";
import AssetsContext from "../../../../assets_context";
import { BLOK_ARRAY, MEDIA } from "../../../../prop_types";
import VideoPlayButton from "../video_play_button/video_play_button";
import useLazyLoadedVideoSrc from "./hooks/use_lazy_loaded_video_src";
import useVideoPlayingState from "./hooks/use_video_playing_state";
import {
  hasBorderRadiusStyle,
  hasShadowStyle,
  videoStyle,
  wrapperStyle,
} from "./video.module.scss";

export default function Video({
  video,
  poster,
  settings,
  width,
  hasControls,
  preload,
  hasBorderRadius,
  hasShadow,
  ...rest
}) {
  const { assets } = useContext(AssetsContext);
  const [settingsClassNames] = useSettingsClassNames(settings);

  const { isPlaying, toggleIsPlaying, onPlay, onPause, videoRef } =
    useVideoPlayingState({
      hasControls,
    });

  const videoClassNames = classNames(videoStyle, ...settingsClassNames, {
    [hasShadowStyle]: hasShadow,
    [hasBorderRadiusStyle]: hasBorderRadius,
  });

  const posterSrc = useMemo(() => {
    if (poster) {
      const posterAsset = assets[`File_${poster.id}`];
      return poster && isEditorRoute()
        ? poster.filename
        : posterAsset?.publicURL;
    }
    return null;
  }, [assets, poster]);

  const [inViewRef, inViewIOEntry] = useIntersect({
    rootMargin: "0% 0% 0% 0%",
  });

  const { lazyLoadedAssetSrc, videoAssetAspectRatio } = useLazyLoadedVideoSrc({
    id: video?.id,
    filename: video.filename,
    inView: inViewIOEntry.isIntersecting,
  });

  // No image/filename defined, probably a new image component defined in the CMS
  // if (!assetSrc) {
  //   return null;
  // }

  return (
    <SbEditable content={rest}>
      <AspectRatio ratio={videoAssetAspectRatio}>
        <div
          ref={inViewRef}
          data-testid="video-wrapper"
          className={wrapperStyle}
        >
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            ref={videoRef}
            className={videoClassNames}
            src={lazyLoadedAssetSrc}
            playsInline={!hasControls}
            autoPlay={!hasControls}
            controls={hasControls}
            loop
            muted={!hasControls}
            poster={posterSrc}
            preload={preload || undefined}
            controlsList="nodownload"
            onPlaying={onPlay}
            onPause={onPause}
          />
          {hasControls && (
            <VideoPlayButton isPlaying={isPlaying} onClick={toggleIsPlaying} />
          )}
        </div>
      </AspectRatio>
    </SbEditable>
  );
}

Video.defaultProps = {
  video: null,
  poster: null,
  settings: null,
  width: null,
  hasControls: false,
  preload: false,
  hasShadow: false,
  hasBorderRadius: false,
};

Video.propTypes = {
  video: MEDIA,
  poster: MEDIA,
  settings: BLOK_ARRAY,
  width: PropTypes.string,
  hasControls: PropTypes.bool,
  preload: PropTypes.bool,
  hasShadow: PropTypes.bool,
  hasBorderRadius: PropTypes.bool,
};
