import React from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import * as classes from "./tick_list_item.module.scss";

export default function TickListItem({ text, isDisabled, ...rest }) {
  const classNames = [classes.tickListItem];

  if (isDisabled) {
    classNames.push(classes.isDisabled);
  }

  return (
    <SbEditable content={rest}>
      <div className={classNames.join(" ")}>
        <img
          alt=""
          className={classes.icon}
          src={`/images/icon_${isDisabled ? "tick_empty" : "tick"}.svg#icon`}
        />
        {text}
      </div>
    </SbEditable>
  );
}

TickListItem.defaultProps = {
  text: null,
  isDisabled: false,
};

TickListItem.propTypes = {
  text: PropTypes.string,
  isDisabled: PropTypes.bool,
};
