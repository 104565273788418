import React from "react";
import PropTypes from "prop-types";
import BackgroundImage from "~components/molecules/background_image/background_image";
import AspectRatio from "~components/utils/aspect_ratio/aspect_ratio";
import useSettingsClassNames from "~hooks/use_settings_classnames/use_settings_classnames";
import { BLOK_ARRAY, MEDIA } from "../../../prop_types";
import * as classes from "./avatar.module.scss";

export default function Avatar({ settings, width, image }) {
  const [settingsClassNames] = useSettingsClassNames(settings);

  const classNames = [classes.avatar, ...settingsClassNames];

  const style = {};

  if (width) {
    style.width = `${width}px`;
  }

  return (
    <div
      className={classNames.join(" ")}
      style={style}
      data-testid="avatar-icon"
    >
      <AspectRatio ratio={1}>
        <BackgroundImage image={image} objectFit="cover" width={width} />
      </AspectRatio>
    </div>
  );
}

Avatar.defaultProps = {
  settings: null,
  width: 64,
  image: null,
};

Avatar.propTypes = {
  settings: BLOK_ARRAY,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: MEDIA,
};
