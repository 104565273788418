import { useCallback, useRef, useState } from "react";

export default function useVideoPlayingState({ hasControls }) {
  const [isPlaying, setIsPlaying] = useState(!hasControls);
  const videoRef = useRef();

  const toggleIsPlaying = useCallback(() => {
    setIsPlaying((prevWasPlaying) => {
      if (videoRef.current && !prevWasPlaying) {
        return videoRef.current.play();
      }
      if (videoRef.current && prevWasPlaying) {
        return videoRef.current.pause();
      }
      return !prevWasPlaying;
    });
  }, []);

  const onPlay = useCallback(() => {
    return setIsPlaying(true);
  }, [setIsPlaying]);

  const onPause = useCallback(() => {
    return setIsPlaying(false);
  }, [setIsPlaying]);

  return { isPlaying, toggleIsPlaying, onPlay, onPause, videoRef };
}
