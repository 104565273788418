import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import SVGIcon from "~components/utils/svg_icon/svg_icon";
import {
  isVisibleStyle,
  playButtonStyle,
} from "./video_play_button.module.scss";

function VideoPlayButton({ isPlaying, onClick }) {
  const playButtonClassNames = classNames(playButtonStyle, {
    [isVisibleStyle]: !isPlaying,
  });
  return (
    <button type="button" onClick={onClick} className={playButtonClassNames}>
      <SVGIcon icon="icon_play" color="white" />
    </button>
  );
}

VideoPlayButton.propTypes = {
  onClick: PropTypes.func,
  isPlaying: PropTypes.bool,
};

VideoPlayButton.defaultProps = {
  onClick: () => {},
  isPlaying: null,
};

export default VideoPlayButton;
