import { useContext, useEffect, useMemo, useState } from "react";
import { isEditorRoute } from "~utils/storyblok/storyblok";
import AssetsContext from "../../../../../assets_context";

export default function useLazyLoadedVideoSrc({ id, filename, inView }) {
  const [lazyLoadedAssetSrc, setLazyLoadedAssetSrc] = useState(null);
  const { assets } = useContext(AssetsContext);
  // get asset from context
  const videoAsset = useMemo(() => {
    return assets[`File_${id}`];
  }, [assets, id]);

  const videoAssetAspectRatio = videoAsset?.fields?.aspectRatio;

  // get specific asset based on filename
  const assetSrc = useMemo(() => {
    return isEditorRoute() ? filename : videoAsset?.publicURL;
  }, [filename, videoAsset?.publicURL]);

  // set src when in view (lazy load)
  useEffect(() => {
    // don't update src when already set
    if (inView && assetSrc !== lazyLoadedAssetSrc) {
      requestAnimationFrame(() => {
        return setLazyLoadedAssetSrc(assetSrc);
      });
    }
    return null;
  }, [assetSrc, inView, lazyLoadedAssetSrc]);

  return { lazyLoadedAssetSrc, assetSrc, videoAssetAspectRatio };
}
