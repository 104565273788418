// Polyfill, mainly for old(er) iOS
// https://caniuse.com/mdn-api_intersectionobserver
import "intersection-observer";

// based on https://medium.com/the-non-traditional-developer/how-to-use-an-intersectionobserver-in-a-react-hook-9fb061ac6cb5
import { useEffect, useRef, useState } from "react";

export default function useIntersect({
  root = null,
  rootMargin,
  threshold = 0,
} = {}) {
  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState(null);

  const observer = useRef(null);

  useEffect(() => {
    if (observer.current) {
      //  make sure to disconnect any stale nodes
      observer.current.disconnect();
    }

    //  make sure we're creating new intersection observer
    //  when node or params for observer change
    observer.current = new IntersectionObserver(
      ([e]) => {
        updateEntry(e);
      },
      {
        root,
        rootMargin,
        threshold,
      }
    );

    if (node) {
      observer.current.observe(node);
    }

    //  cleanup
    return () => {
      observer.current.disconnect();
    };
  }, [node, root, rootMargin, threshold]);

  return [setNode, entry];
}
