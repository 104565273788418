import React from "react";
import useSettingsClassNames from "~hooks/use_settings_classnames/use_settings_classnames";
import { BLOK_ARRAY } from "../../../prop_types";
import TickListItem from "./components/tick_list_item/tick_list_item";
import * as classes from "./tick_list.module.scss";

export default function TickList({ items, settings }) {
  const [settingsClassNames] = useSettingsClassNames(settings);
  const classNames = [classes.tickList, ...settingsClassNames];

  return (
    <ul className={classNames.join(" ")}>
      {items &&
        items.map((item) => {
          return (
            <li key={item._uid} className={classes.tickListItem}>
              <TickListItem {...item} />
            </li>
          );
        })}
    </ul>
  );
}

TickList.defaultProps = {
  items: null,
  settings: null,
};

TickList.propTypes = {
  items: BLOK_ARRAY,
  settings: BLOK_ARRAY,
};
